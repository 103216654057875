// Carousel
.carouselDetailWrapper h2 a {
  border-bottom: 3px solid transparent;
  transition: border-color 500ms ease-out 50ms;
  &:hover {
    text-decoration: none;
    border-bottom-color: $brand-primary;
  }
}

// Home Features
.homeFeatures .homeFeature { 
  h2 a {
    border-bottom: 3px solid transparent;
    transition: border-color 500ms ease-out 50ms;
  }
  [class*="image-link"] {
    background-color: transparent;
  }
  &:hover { 
    h2 a {
      color: $body-colour;
      text-decoration: none;
      border-bottom-color: $brand-primary;
    }
    [class*="image-link"] .banner {
      opacity: 1;
    }
  }
}

// Donation
.quickGivingPanel,
.homefeaturecategory-homeboxquickgiving .quickGivingPanel { 
  .formQuestion.donationAmount {
    border-color: #f0f0f0;
    background-color: #fff;
    transition: border-color 500ms ease-out 50ms;
    &.donationSelected {
      border-color: $brand-primary;
      .donationAmountFigure {
        background-color: #fff;
      }
    }
    &:hover {
      background-color: #fff;
    }
    &.donationAmountOther {
      background-color: transparent;
      #donationAmountOther {
        position: relative;
        max-width: 100px;
        padding: 5px;
        height: auto;
        font-size: 100%;
        line-height: normal;
        background: #fff;
        opacity: 1;
        &:focus {
          outline: none;
        }
      }
    }
    .donationAmountFigure {
      line-height: normal;
      background-color: transparent;
    }
  }
}
.homefeaturecategory-homeboxquickgiving .quickGivingPanel .donateBtn  {
  height: 44px;
}

// Feed
.feedItem {
  .feedTitle a {
    border-bottom: 3px solid transparent;
    transition: border-color 500ms ease-out 50ms;
  }
  &:hover .feedTitle a {
    color: $body-colour;
    text-decoration: none;
    border-bottom-color: $brand-primary;
  }
}
.homeFeedevents .feedItem footer .associatedStartDate {
  opacity: 1;
  font-size: 1rem;
  font-weight: 700;
}

// Menu Admin
#menuAdmin {
  li {
    margin: 0;
    height: 40px;
    display: inline-flex;
    align-items: center;
  }

  .menuAdminSearch {
    order: 5;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    justify-content: flex-end;
    position: relative;

    button {
      position: absolute;
      right: 0;
    }
  }

  #siteSearch {
    max-width: $header-search-input-max-width;
  }

  .socialIcons {
    order: 6;
    margin: 0;

    li {
      margin: 0;
      padding: 0;
    }
  }
}

.pageHeader .headerContent .mainCallToAction {
  margin-left: auto;
}

.homepage-quickgiving--container {
  flex-direction: column;
}

.homepage-quickgiving--copy {
  margin: 0 0 1rem;
}

.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem),
.listedProduct {
  .productPrice {
    transition: color 500ms ease-out 50ms;
  }
  h2 a {
    border-bottom: 3px solid transparent;
    transition: border-color 500ms ease-out 50ms;
  }
  &:hover { 
    h2 a {
      color: $body-colour;
      text-decoration: none;
      border-bottom-color: $brand-primary;
    }
  }
}

// Events
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) footer .postFooter > :not(.button) {
  opacity: 1;
  font-size: 1rem;
  font-weight: 700;
}

// Footer
.Footer-Legal {
  text-align: center;
}

// Header mobile fixes
@media (max-width: map-get($breakpoints, sm)) {
  #menuAdmin .menuAdminSearch {
    display: none;
  }
  #menuAdmin .socialIcons {
    display: none;
  }
}

// Donation complete page hide default donation share button
body.pages-payment-complete .shareWrapper.donationShare .cta-button {
  display: none;
}

//Remove tabs from Appeals listing page
.donation-listing-page .tabsWrapper {
  display: none;
}

// Tidy footer
.footerBox[class*="ewsletter"] > p {
  max-width: 1170px;
  padding: 0 20px;
  margin-left: auto;
  margin-right: auto;
}

// Hide breadcrumbs
nav.breadcrumbWrapper {
  display: none;
}

// Hide mobile nav as nav is not in use
a.menuMainAlt {
  display: none!important;
}

// Buttons - remove hover state
.cta-button:hover, 
.button:hover, 
button:hover, 
.readMore:hover, 
.addToCalender:hover,
.mainCallToAction .cta-button:not([class*="donate"]):hover,
.footerBox[class*="ewsletter"] button:hover {
  background-color: lighten($brand-primary,2.5%);
}